import { fetchAPI, parseBody } from './common';

export async function list() {
  const res = await fetchAPI(`/user`, 'get');
  const users = await parseBody(res);
  return users;
}

export async function postUser(data) {
  const res = await fetchAPI(`/user`, 'post', data);
  const user = await parseBody(res);
  return user;
}

export async function updateUser(userId, data) {
  const res = await fetchAPI(`/user/${userId}`, 'put', data);
  const user = await parseBody(res);
  return user;
}

export async function getById(id) {
  const res = await fetchAPI(`/user/${id}`, 'get');
  const user = await parseBody(res);
  return user;
}

export async function deleteUser(id) {
  const res = await fetchAPI(`/user/${id}`, 'delete');
  const user = await parseBody(res);
  return user;
}

export async function sendResetPassOTP(data) {
  const res = await fetchAPI(`/auth/reset-password-otp`, 'post', data);
  const otp = await parseBody(res);
  return otp;
}

export async function resetPassWithOTP(data) {
  const res = await fetchAPI(`/auth/reset-password`, 'patch', data);
  const msg = await parseBody(res);
  return msg;
}

export async function updatePassword(data) {
  const res = await fetchAPI(`/auth/update-password`, 'patch', data);
  const msg = await parseBody(res);
  return msg;
}
