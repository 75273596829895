import { createBrowserHistory } from 'history';

// sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { StyledChart } from './components/chart';
import SnackbarProvider from './components/snackbar';
import { MotionLazyContainer } from './components/animate';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://d6fea09686e33f922dbea76cb78b8f60@o89831.ingest.sentry.io/4505707115249664',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(history),
    }),
  ],
  environment: 'production',
  tracesSampleRate: 1.0,
});

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeLocalization>
          <SnackbarProvider>
            <StyledChart />
            <Router />
          </SnackbarProvider>
        </ThemeLocalization>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
