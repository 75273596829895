import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts

import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,

  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCreatePage,
  UserAccountPage,

  // Dashboard: User
  ClientListPage,

  //
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  ClientDetailsPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralAppPage /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'list', element: <UserListPage /> },
            { path: 'new', element: <UserCreatePage /> },
            { path: 'edit/:id', element: <UserEditPage /> },
            { path: 'account', element: <UserAccountPage /> },
          ],
        },
        {
          path: 'client',
          children: [
            { element: <Navigate to="/dashboard/client/list" replace />, index: true },

            { path: 'list', element: <ClientListPage /> },

            { path: 'edit/:id', element: <UserEditPage /> },
            { path: 'view/:id', element: <ClientDetailsPage /> },
          ],
        },
      ],
    },

    // Main Routes
    // {
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <AboutPage /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <FaqsPage /> },
    //     // Demo Components
    //     {
    //       path: 'components',
    //       children: [
    //         { element: <ComponentsOverviewPage />, index: true },
    //         {
    //           path: 'foundation',
    //           children: [
    //             { element: <Navigate to="/components/foundation/colors" replace />, index: true },
    //             { path: 'colors', element: <FoundationColorsPage /> },
    //             { path: 'typography', element: <FoundationTypographyPage /> },
    //             { path: 'shadows', element: <FoundationShadowsPage /> },
    //             { path: 'grid', element: <FoundationGridPage /> },
    //             { path: 'icons', element: <FoundationIconsPage /> },
    //           ],
    //         },
    //         {
    //           path: 'mui',
    //           children: [
    //             { element: <Navigate to="/components/mui/accordion" replace />, index: true },
    //             { path: 'accordion', element: <MUIAccordionPage /> },
    //             { path: 'alert', element: <MUIAlertPage /> },
    //             { path: 'autocomplete', element: <MUIAutocompletePage /> },
    //             { path: 'avatar', element: <MUIAvatarPage /> },
    //             { path: 'badge', element: <MUIBadgePage /> },
    //             { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
    //             { path: 'buttons', element: <MUIButtonsPage /> },
    //             { path: 'checkbox', element: <MUICheckboxPage /> },
    //             { path: 'chip', element: <MUIChipPage /> },
    //             { path: 'data-grid', element: <MUIDataGridPage /> },
    //             { path: 'dialog', element: <MUIDialogPage /> },
    //             { path: 'list', element: <MUIListPage /> },
    //             { path: 'menu', element: <MUIMenuPage /> },
    //             { path: 'pagination', element: <MUIPaginationPage /> },
    //             { path: 'pickers', element: <MUIPickersPage /> },
    //             { path: 'popover', element: <MUIPopoverPage /> },
    //             { path: 'progress', element: <MUIProgressPage /> },
    //             { path: 'radio-button', element: <MUIRadioButtonsPage /> },
    //             { path: 'rating', element: <MUIRatingPage /> },
    //             { path: 'slider', element: <MUISliderPage /> },
    //             { path: 'stepper', element: <MUIStepperPage /> },
    //             { path: 'switch', element: <MUISwitchPage /> },
    //             { path: 'table', element: <MUITablePage /> },
    //             { path: 'tabs', element: <MUITabsPage /> },
    //             { path: 'textfield', element: <MUITextFieldPage /> },
    //             { path: 'timeline', element: <MUITimelinePage /> },
    //             { path: 'tooltip', element: <MUITooltipPage /> },
    //             { path: 'transfer-list', element: <MUITransferListPage /> },
    //             { path: 'tree-view', element: <MUITreesViewPage /> },
    //           ],
    //         },
    //         {
    //           path: 'extra',
    //           children: [
    //             { element: <Navigate to="/components/extra/animate" replace />, index: true },
    //             { path: 'animate', element: <DemoAnimatePage /> },
    //             { path: 'carousel', element: <DemoCarouselsPage /> },
    //             { path: 'chart', element: <DemoChartsPage /> },
    //             { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
    //             { path: 'editor', element: <DemoEditorPage /> },
    //             { path: 'form-validation', element: <DemoFormValidationPage /> },
    //             { path: 'image', element: <DemoImagePage /> },
    //             { path: 'label', element: <DemoLabelPage /> },
    //             { path: 'lightbox', element: <DemoLightboxPage /> },
    //             { path: 'map', element: <DemoMapPage /> },
    //             { path: 'mega-menu', element: <DemoMegaMenuPage /> },
    //             { path: 'multi-language', element: <DemoMultiLanguagePage /> },
    //             { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
    //             { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
    //             { path: 'scroll', element: <DemoScrollbarPage /> },
    //             { path: 'snackbar', element: <DemoSnackbarPage /> },
    //             { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
    //             { path: 'upload', element: <DemoUploadPage /> },
    //           ],
    //         },
    //       ],
    //     },
    //   ],
    // },

    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
